export default { 
 
 mutation_id( state, valor) {   
              state.evidencias_id   = valor 
            
 },
 mutation_nombre( state, valor) {   
              state.evidencias_nombre   = valor 
            
 },
 mutation_descripcion( state, valor) {   
              state.evidencias_descripcion   = valor 
            
 },
 mutation_ubicacion( state, valor) {   
              state.evidencias_ubicacion   = valor 
            
 },
 mutation_tipo( state, valor) {   
              state.evidencias_tipo   = valor 
            
 },
 mutation_investigacionid( state, valor) {   
              state.evidencias_investigacionid   = valor 
            
 },
 mutation_activo( state, valor) {   
              state.evidencias_activo   = valor 
            
 },
                                                                           }