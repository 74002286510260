import mutations from './mutations.js'; 
 import actions from './actions.js'; 
 
 export default { 
 
     state : {   
 roles_id: null  ,
roles_NOMBREDELROL: null  ,
roles_ALTADECATALOGOS: null  ,
roles_BAJADECATALOGOS: null  ,
roles_MODIFICACIOnDECATALOGOS: null  ,
roles_ALTADEUSUARIOS: null  ,
roles_BAJADEUSUARIOS: null  ,
roles_MODIFICACIONDEUSUARIOS: null  ,
roles_ALTADEROL: null  ,
roles_BAJADEROL: null  ,
roles_MODIFICACIONDEROL: null  ,
roles_ALTADEVALORACIONINICIAL: null  ,
roles_MODIFICACIONREAPERTURAVALORACIONINICIAL: null  ,
roles_EDITARANTESDECIERREDELAVALORACIONINICIAL: null  ,
roles_BAJAVALORACIONINICIAL: null  ,
roles_IMPRESIONVALORACIONINICIAL: null  ,
roles_VISUALIZACIONVALORACIONINICIAL: null  ,
roles_ALTADEVALORACIONINTEGRAL: null  ,
roles_MODIFICACIONREAPERTURAVALORACIONINTEGRAL: null  ,
roles_EDITARANTESDECIERREDELAVALORACIONINTEGRAL: null  ,
roles_BAJAVALORACIONINTEGRAL: null  ,
roles_IMPRESIONVALORACIONINTEGRAL: null  ,
roles_VISUALIZACIONVALORACIONINTEGRAL: null  ,
roles_ALTADESEGUIMIENTO: null  ,
roles_MODIFICACIONDESEGUIMIENTO: null  ,
roles_EDITARDESEGUIMIENTO: null  ,
roles_BAJADESEGUIMIENTO: null  ,
roles_IMPRESIONDESEGUIMIENTO: null  ,
roles_VISUALIZACIONDESEGUIMIENTO: null  ,
roles_ALTADECIERRE: null  ,
roles_MODIFICACIONDECIERRE: null  ,
roles_EDICIONDECIERRE: null  ,
roles_BAJADECIERRE: null  ,
roles_IMPRESIONDECIERRE: null  ,
roles_VISUALIZACIONDECIERRE: null  ,
roles_ALTADENUNCIA: null  ,
roles_MODIFCACIONDENUNCIA: null  ,
roles_EDICIONDENUNCIA: null  ,
roles_BAJADENUNCIA: null  ,
roles_IMPRESIONDENUNCIA: null  ,
roles_VISUALIZACIONDENUNCIA: null  ,

roles_ALTAINVESTIGACION: null  ,
roles_MODIFICACIONINVESTIGACION: null  ,
roles_EDICIONINVESTIGACION: null  ,
roles_BAJAINVESTIGACION: null  ,
roles_IMPRESIONINVESTIGACION: null  ,
roles_VISUALIZACIONINVESTIGACION:null,
roles_ALTAEVIDENCIA: null  ,
roles_MODIFCACIONEVIDENCIA: null  ,
roles_EDICIONEVIDENCIA: null  ,
roles_BAJAEVIDENCIA: null  ,
roles_IMPRESIONEVIDENCIA: null  ,
roles_VISUALIZACIONEVIDENCIA: null  ,
roles_ALTADEARCHIVOS: null  ,
roles_MODIFICACIONARCHIVOS: null  ,
roles_IMPRESIONARCHIVOS: null  ,
roles_VISUALIZACIONARCHIVOS: null  ,
roles_ACTIVO : null,
roles_RECIBECORREOS: null,
roles_VISIBILIDADDEINCIDENTES: null,
roles_AUTORIZAIMPRESION: null,
              }, 
mutations,
actions 
   }