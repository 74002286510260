export default { 

    

    mutation_parametros_conexionsas( state, valor) {   
        state.parametros_conexionsas = valor 
      
},
 
 mutation_parametros_id( state, valor) {   
              state.parametros_id = valor 
            
 },
 mutation_parametros_nombreParametro( state, valor) {   
              state.parametros_nombreParametro = valor 
            
 },
 mutation_parametros_descripcion( state, valor) {   
              state.parametros_descripcion = valor 
            
 },
 mutation_parametros_valor( state, valor) {   
              state.parametros_valor = valor 
            
 },
                                                                           }