export default { 
 
 mutation_permisosimpresion_id( state, valor) {   
       state.permisosimpresion_id = valor 
            
 },
 mutation_permisosimpresion_usuarioid( state, valor) {   
              state.permisosimpresion_usuarioid = valor 
            
 },
 mutation_permisosimpresion_incidenteid( state, valor) {   
              state.permisosimpresion_incidenteid = valor 
            
 },
 mutation_permisosimpresion_etapa( state, valor) {   
              state.permisosimpresion_etapa = valor 
            
 },
 mutation_permisosimpresion_password( state, valor) {   
              state.permisosimpresion_password = valor 
            
 },
 mutation_permisosimpresion_respuesta( state, valor) {   
              state.permisosimpresion_respuesta = valor 
            
 },
 mutation_permisosimpresion_usuarioidautorizo( state, valor) {   
              state.permisosimpresion_usuarioidautorizo = valor 
            
 },
 mutation_permisosimpresion_vigente( state, valor) {   
              state.permisosimpresion_vigente = valor 
            
 },
 mutation_permisosimpresion_fechapeticion( state, valor) {   
              state.permisosimpresion_fechapeticion = valor 
            
 },
 mutation_permisosimpresion_fechaautorizacion( state, valor) {   
              state.permisosimpresion_fechaautorizacion = valor 
            
 },
                                                                           }