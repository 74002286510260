export default { 
 
 action_roles_id ( context , valor ) { 
                         context.commit('mutation_id',valor)  
                        
  },  
 
 action_NOMBREDELROL ( context , valor ) { 
  context.commit('mutation_NOMBREDELROL',valor)  
  },

 action_ALTADECATALOGOS ( context , valor ) { 
   context.commit('mutation_ALTADECATALOGOS',valor)  
  },

 action_BAJADECATALOGOS ( context , valor ) { 
  context.commit('mutation_BAJADECATALOGOS',valor)  
  } ,
 action_MODIFICACIOnDECATALOGOS ( context , valor ) { 
context.commit('mutation_MODIFICACIOnDECATALOGOS',valor)  
},




 action_ALTADEUSUARIOS ( context , valor ) { 
                         context.commit('mutation_ALTADEUSUARIOS',valor)  
                        
  }  
 ,
 action_BAJADEUSUARIOS ( context , valor ) { 
                         context.commit('mutation_BAJADEUSUARIOS',valor)  
                        
  }  
 ,
 action_MODIFICACIONDEUSUARIOS ( context , valor ) { 
                         context.commit('mutation_MODIFICACIONDEUSUARIOS',valor)  
                        
  }  
 ,


 action_ALTADEROL ( context , valor ) { 
                         context.commit('mutation_ALTADEROL',valor)  
                        
  }  
 ,
 action_BAJADEROL ( context , valor ) { 
                         context.commit('mutation_BAJADEROL',valor)  
                        
  }  
 ,
 action_MODIFICACIONDEROL ( context , valor ) { 
                         context.commit('mutation_MODIFICACIONDEROL',valor)  
                        
  }  
 ,



 action_ALTADEVALORACIONINICIAL ( context , valor ) { 
                         context.commit('mutation_ALTADEVALORACIONINICIAL',valor)  
                        
  }  
 ,
 action_MODIFICACIONREAPERTURAVALORACIONINICIAL ( context , valor ) { 
                         context.commit('mutation_MODIFICACIONREAPERTURAVALORACIONINICIAL',valor)  
                        
  }  
 ,
 action_EDITARANTESDECIERREDELAVALORACIONINICIAL ( context , valor ) { 
                         context.commit('mutation_EDITARANTESDECIERREDELAVALORACIONINICIAL',valor)  
                        
  }  
 ,
 action_BAJAVALORACIONINICIAL ( context , valor ) { 
                         context.commit('mutation_BAJAVALORACIONINICIAL',valor)  
                        
  }  
 ,
 action_IMPRESIONVALORACIONINICIAL ( context , valor ) { 
                         context.commit('mutation_IMPRESIONVALORACIONINICIAL',valor)  
                        
  }  
 ,
 action_VISUALIZACIONVALORACIONINICIAL ( context , valor ) { 
                         context.commit('mutation_VISUALIZACIONVALORACIONINICIAL',valor)  
                        
  }  
 ,





 ///->hasta aqui

 
 action_ALTADEVALORACIONINTEGRAL ( context , valor ) { 
                         context.commit('mutation_ALTADEVALORACIONINTEGRAL',valor)  
                        
  }  
 ,
 action_MODIFICACIONREAPERTURAVALORACIONINTEGRAL ( context , valor ) { 
                         context.commit('mutation_MODIFICACIONREAPERTURAVALORACIONINTEGRAL',valor)  
                        
  }  
 ,
 action_EDITARANTESDECIERREDELAVALORACIONINTEGRAL ( context , valor ) { 
                         context.commit('mutation_EDITARANTESDECIERREDELAVALORACIONINTEGRAL',valor)  
                        
  }  
 ,
 action_BAJAVALORACIONINTEGRAL ( context , valor ) { 
                         context.commit('mutation_BAJAVALORACIONINTEGRAL',valor)  
                        
  }  
 ,
 action_IMPRESIONVALORACIONINTEGRAL ( context , valor ) { 
                         context.commit('mutation_IMPRESIONVALORACIONINTEGRAL',valor)  
                        
  }  
 ,
 action_VISUALIZACIONVALORACIONINTEGRAL ( context , valor ) { 
                         context.commit('mutation_VISUALIZACIONVALORACIONINTEGRAL',valor)  
                        
  }  
 ,


 ///->.....
 action_ALTADESEGUIMIENTO ( context , valor ) { 
                         context.commit('mutation_ALTADESEGUIMIENTO',valor)  
                        
  }  
 ,
 action_MODIFICACIONDESEGUIMIENTO ( context , valor ) { 
                         context.commit('mutation_MODIFICACIONDESEGUIMIENTO',valor)  
                        
  }  
 ,
 action_EDITARDESEGUIMIENTO ( context , valor ) { 
                         context.commit('mutation_EDITARDESEGUIMIENTO',valor)  
                        
  }  
 ,
 action_BAJADESEGUIMIENTO ( context , valor ) { 
                         context.commit('mutation_BAJADESEGUIMIENTO',valor)  
                        
  }  
 ,
 action_IMPRESIONDESEGUIMIENTO ( context , valor ) { 
                         context.commit('mutation_IMPRESIONDESEGUIMIENTO',valor)  
                        
  }  
 ,
 action_VISUALIZACIONDESEGUIMIENTO ( context , valor ) { 
                         context.commit('mutation_VISUALIZACIONDESEGUIMIENTO',valor)  
                        
  }  
 ,

//termina seguimiento


 action_ALTADECIERRE ( context , valor ) { 
 context.commit('mutation_ALTADECIERRE',valor)  }  
 ,
 action_MODIFICACIONDECIERRE ( context , valor ) { 
 context.commit('mutation_MODIFICACIONDECIERRE',valor)  }  
 ,
 action_EDICIONDECIERRE ( context , valor ) { 
 context.commit('mutation_EDICIONDECIERRE',valor)  }  
 ,
 action_BAJADECIERRE ( context , valor ) { 
 context.commit('mutation_BAJADECIERRE',valor)  }  
 ,
 action_IMPRESIONDECIERRE ( context , valor ) { 
 context.commit('mutation_IMPRESIONDECIERRE',valor)  }  
 ,
 action_VISUALIZACIONDECIERRE ( context , valor ) { 
 context.commit('mutation_VISUALIZACIONDECIERRE',valor)  }  
 ,
//termina cierre


 action_ALTADENUNCIA ( context , valor ) { 
 context.commit('mutation_ALTADENUNCIA',valor)}  
 ,
 action_MODIFCACIONDENUNCIA ( context , valor ) { 
 context.commit('mutation_MODIFCACIONDENUNCIA',valor)}  
 ,
 action_EDICIONDENUNCIA ( context , valor ) { 
 context.commit('mutation_EDICIONDENUNCIA',valor)}  
 ,
 action_BAJADENUNCIA ( context , valor ) { 
 context.commit('mutation_BAJADENUNCIA',valor)}  
 ,
 action_IMPRESIONDENUNCIA ( context , valor ) { 
 context.commit('mutation_IMPRESIONDENUNCIA',valor)}  
 ,
 action_VISUALIZACIONDENUNCIA ( context , valor ) { 
 context.commit('mutation_VISUALIZACIONDENUNCIA',valor)}  
 ,
 //termina denuncia}

 action_ALTAINVESTIGACION ( context , valor ) { 
 context.commit('mutation_ALTAINVESTIGACION',valor)}  
 ,
 action_MODIFICACIONINVESTIGACION ( context , valor ) { 
 context.commit('mutation_MODIFICACIONINVESTIGACION',valor)}  
 ,
 action_EDICIONINVESTIGACION ( context , valor ) { 
 context.commit('mutation_EDICIONINVESTIGACION',valor)}  
 ,
 action_BAJAINVESTIGACION ( context , valor ) { 
 context.commit('mutation_BAJAINVESTIGACION',valor)}  
 ,
 action_IMPRESIONINVESTIGACION ( context , valor ) { 
 context.commit('mutation_IMPRESIONINVESTIGACION',valor)}  
 ,

 action_VISUALIZACIONINVESTIGACION ( context , valor ) { 
  context.commit('mutation_VISUALIZACIONINVESTIGACION',valor)}  
  ,

 //termina investighacion 
 action_ALTAEVIDENCIA ( context , valor ) { 
 context.commit('mutation_ALTAEVIDENCIA',valor)}  
 ,
 action_MODIFCACIONEVIDENCIA ( context , valor ) { 
 context.commit('mutation_MODIFCACIONEVIDENCIA',valor)}  
 ,
 action_EDICIONEVIDENCIA ( context , valor ) { 
 context.commit('mutation_EDICIONEVIDENCIA',valor)}  
 ,
 action_BAJAEVIDENCIA ( context , valor ) { 
 context.commit('mutation_BAJAEVIDENCIA',valor)}  
 ,
 action_IMPRESIONEVIDENCIA ( context , valor ) { 
 context.commit('mutation_IMPRESIONEVIDENCIA',valor)}  
 ,
 action_VISUALIZACIONEVIDENCIA ( context , valor ) { 
 context.commit('mutation_VISUALIZACIONEVIDENCIA',valor)}  
 ,
 // termina EVIDENCIA
 action_ALTADEARCHIVOS ( context , valor ) { 
 context.commit('mutation_ALTADEARCHIVOS',valor)}  
 ,
 action_MODIFICACIONARCHIVOS ( context , valor ) { 
 context.commit('mutation_MODIFICACIONARCHIVOS',valor)}  
 ,
 action_IMPRESIONARCHIVOS ( context , valor ) { 
 context.commit('mutation_IMPRESIONARCHIVOS',valor)}  
 ,
 action_VISUALIZACIONARCHIVOS ( context , valor ) { 
 context.commit('mutation_VISUALIZACIONARCHIVOS',valor)}  
 ,

 action_roles_activo( context , valor ) { 
  context.commit('mutation_roles_activo',valor)}  
  ,
  action_roles_RECIBECORREOS( context , valor ) { 
    context.commit('mutation_roles_RECIBECORREOS',valor)}  
    ,

    action_roles_VISIBILIDADDEINCIDENTES( context , valor ) { 
      context.commit('mutation_roles_VISIBILIDADDEINCIDENTES',valor)},
 
      
      action_roles_AUTORIZAIMPRESION( context , valor ) { 
        context.commit('mutation_roles_AUTORIZAIMPRESION',valor)}
  


   }
   
   


   