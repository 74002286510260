export default { 
 
    mutation_notificacion_incidenteid( state, valor) {   
             state.notificacion_incidenteid   = valor 
           
},

mutation_notificacion_respuesta( state, valor) {   
    state.notificacion_respuesta   = valor 
  
},
mutation_notificacion_texto_respuesta( state, valor) {   
    state.notificacion_texto_respuesta   = valor 
  
}



    
    
    
    }//termina