<template>
  <v-container>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="2"></v-col>
      <v-col  cols="12" xs="12" sm="12" md="8">
        <h2 class="text-center">Plataforma Interna de Salvaguarda Infantil y Juvenil</h2>
      </v-col>
      <v-col  cols="12" xs="12" sm="12" md="2"></v-col>
    </v-row>

    <v-row>
      <v-col></v-col>
      <v-col>
        <img
          alt="Vue logo"
          src="https://www.aldeasinfantiles.org.mx/getmedia/51757cf7-a274-4bdc-b552-4304db77c698/logo-aldeas-mx"
        />
      </v-col>
      <v-col></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="2"></v-col>
      <v-col  cols="12" xs="12" sm="12" md="8">
        <h2 class="text-center">  </h2>
      </v-col>
      <v-col  cols="12" xs="12" sm="12" md="2"></v-col>
    </v-row>

    <v-row>
      <v-col></v-col>
      <v-col>
        <!-- <ul>
          <li>
            Se reestablece conexion a base de datos en las fases de 
            valoracion inicial y valoracion integral.
          </li>
          <li>
            Cambios menores a la interfaz de usuario en
            <a href="#/seguimiento">etapa de seguimiento</a>
          </li>
          <li>
            Se realizan Cambio a interfaz de usuarios (Casillas de verficación
            por Listas Desplegables) en pantalla de
            <a href="#/denuncias">etapa inicial</a> y
            <a href="#/valoracionintegral/2">valoración integral</a>
          </li>

            <li>
            Cambios a interfaz de usuario en Dashboad( tablero de dato)
          
          </li>
          <li>
            se agrega pantalla de confirmación de alta de reporte
          </li>
                    <li>
            se agrega pantalla de solicitud de permiso de impresion en valoracion incial
          </li>
        </ul> -->
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src


export default {
  name: "Home",
  components: {},
  
  mounted () {
    
  },
};
</script>
