import mutations from './mutations.js'; 
 import actions from './actions.js'; 
 
 export default { 
 
     state : {                             
      doctosapoyo_id : null ,
      doctosapoyo_nombredocto: null  ,
      doctosapoyo_incidenteid: null  ,
      doctosapoyo_descripcion : null ,
      doctosapoyo_link : null ,
      doctosapoyo_categoria : null ,
      doctosapoyo_activo: null  ,
              },  
                      mutations, 
                      actions 
   }