import mutations from './mutations.js'; 
 import actions from './actions.js'; 
 
 export default { 
 
     state : {                             
permisosimpresion_id: null,  
permisosimpresion_usuarioid: null  ,
permisosimpresion_incidenteid: null  ,
permisosimpresion_etapa: null  ,
permisosimpresion_password : null ,
permisosimpresion_respuesta : null ,
permisosimpresion_usuarioidautorizo: null  ,
permisosimpresion_vigente: null  ,
permisosimpresion_fechapeticion: null  ,
permisosimpresion_fechaautorizacion : null ,
              },          mutations, actions 
   }