export default { 
 
  action_doctosapoyo_id ( context , valor ) { 
                         context.commit('mutation_doctosapoyo_id',valor)  
                        
  }  ,
 action_doctosapoyo_nombredocto ( context , valor ) { 
                         context.commit('mutation_doctosapoyo_nombredocto',valor)  
                        
  }  
 ,
 action_doctosapoyo_incidenteid ( context , valor ) { 
                         context.commit('mutation_doctosapoyo_incidenteid',valor)  
                        
  }  
 ,
 action_doctosapoyo_descripcion ( context , valor ) { 
                         context.commit('mutation_doctosapoyo_descripcion',valor)  
                        
  }  
 ,
 action_doctosapoyo_link ( context , valor ) { 
                         context.commit('mutation_doctosapoyo_link',valor)  
                        
  }  
 ,
 action_doctosapoyo_categoria ( context , valor ) { 
                         context.commit('mutation_doctosapoyo_categoria',valor)  
                        
  }  
 ,
 action_doctosapoyo_activo ( context , valor ) { 
                         context.commit('mutation_doctosapoyo_activo',valor)  
                        
  }  
 ,
   
}