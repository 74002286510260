import mutations from './mutations.js'; 
 import actions from './actions.js'; 
 
 export default { 
 
     state : { 

investigacion_id:'',
investigacion_incidenteid :'',
investigacion_folioinvestigacion_docto :'',
investigacion_registroincidentes_docto:'' ,
investigacion_cartautorizacion_docto :'',
investigacion_terminosreferencia_doctp:'' ,
investigacion_plan_docto:'' ,
investigacion_informe_docto:'' ,
investigacion_fechaCreacion :'',
investigacion_fechaUpdate :'',

investigacion_evidencias :[],
investigacion_registroincidentes_docto_nombre:'' ,
investigacion_cartautorizacion_docto_nombre :'',
investigacion_terminosreferencia_doctp_nombre:'' ,
investigacion_plan_docto_nombre:'' ,
investigacion_informe_docto_nombre:'' ,

              },  
 mutations,
 actions 
   }