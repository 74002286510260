export default { 
 
 action_conciencia_id ( context , valor ) { 
                         context.commit('mutation_conciencia_id',valor)  
                        
  }  ,
 
 action_conciencia_estatus ( context , valor ) { 
                         context.commit('mutation_conciencia_estatus',valor)  
                        
  }  
 ,
 action_conciencia_clasificacion ( context , valor ) { 
                         context.commit('mutation_conciencia_clasificacion',valor)  
                        
  }  
 ,
 action_conciencia_activo ( context , valor ) { 
                         context.commit('mutation_conciencia_activo',valor)  
                        
  }  
 ,
 action_conciencia_tipo ( context , valor ) { 
                         context.commit('mutation_conciencia_tipo',valor)  
                        
  }  ,

  /*action_conciencia_docto( context , valor ) { 
  try {
   var b = parseInt(valor);
   if ( Number.isNaN(b)== true){
    b=0;
    context.commit('mutation_conciencia_primermonitoreo_docto', b )
   }else{
    var d = b.toString();
    
    console.log("action_conciencia_docto" + d);
    context.commit('mutation_conciencia_primermonitoreo_docto',d )
   }
  }catch(error){
    console.log(error);
  }
  
  
   
} */

action_conciencia_docto( context , valor ) { 
  try {
   
   // console.log("action_conciencia_docto" + d);
    context.commit('mutation_conciencia_docto',valor )
 
  }catch(error){
    console.log(error);
  }
  
  
   
} ,


action_conciencia_estatusplan( context , valor ) { 
  context.commit('mutation_conciencia_estatusplan',valor)  
 
}  

 ,


 
action_conciencia_primermonitoreo( context, valor) { 

  context.commit('mutation_conciencia_primermonitoreo',valor)

} ,     
action_conciencia_segundomonitoreo( context, valor) { 
  context.commit('mutation_conciencia_segundomonitoreo', valor )

} ,

action_conciencia_primermonitoreo_docto ( context, valor) { 
  context.commit('mutation_conciencia_primermonitoreo_docto',valor )
 /* console.log("valor de primer monitoreo docoto : "  + valor.toString());
  var b = parseInt(valor);
  try{
   if ( Number.isNaN(b)== true){
    b=0;
    context.commit('mutation_conciencia_primermonitoreo_docto', b )
   }else{
    var d = b.toString();
    d= "'" + d +"'";
      console.log("action_conciencia_primermonitoreo_docto" + d);
    context.commit('mutation_conciencia_primermonitoreo_docto',d )
   }
  }catch(error){
    console.log(error);
  }
  */

} ,
action_conciencia_segundomonitoreo_docto ( context, valor) {
  context.commit('mutation_conciencia_segundomonitoreo_docto',valor );
  /*try {
  var b = parseInt(valor);
  if ( Number.isNaN(b)== true){
    b=0;
    context.commit('mutation_conciencia_primermonitoreo_docto', b )
   }else{
    var d = b.toString();
    d= '"' + d +'"';
  
    console.log("action_conciencia_primermonitoreo_docto" + d);
    context.commit('mutation_conciencia_primermonitoreo_docto',d )
   }
  }catch(error){

    console.log(error)
  }*/
}  ,

action_conciencia_carpeta        ( context, valor) {  
  context.commit('mutation_conciencia_carpeta',valor) 

},       
action_conciencia_carpeta_docto  ( context, valor) {  

  context.commit('mutation_conciencia_carpeta_docto',valor );
  /*
  var b = parseInt(valor);
  if ( Number.isNaN(b)== true){
    b=0;
    context.commit('mutation_conciencia_primermonitoreo_docto', b )
   }else{
    var d = b.toString();
    console.log("action_conciencia_primermonitoreo_docto" + d);
    context.commit('mutation_conciencia_primermonitoreo_docto',d )
   }*/

},     
action_conciencia_estado         ( context, valor) { 
  context.commit('mutation_conciencia_estado',valor )

} ,   
                                                                           }