export default { 
 
  mutation_id( state, valor) {   
              state.roles_id   = valor 
            
 },
 mutation_NOMBREDELROL( state, valor) {   
              state.roles_NOMBREDELROL   = valor 
            
 },
 mutation_ALTADECATALOGOS( state, valor) {   
              state.roles_ALTADECATALOGOS   = valor 
            
 },
 mutation_BAJADECATALOGOS( state, valor) {   
              state.roles_BAJADECATALOGOS   = valor 
            
 },
 mutation_MODIFICACIOnDECATALOGOS( state, valor) {   
              state.roles_MODIFICACIOnDECATALOGOS   = valor 
            
 },
 mutation_ALTADEUSUARIOS( state, valor) {   
              state.roles_ALTADEUSUARIOS   = valor 
            
 },
 mutation_BAJADEUSUARIOS( state, valor) {   
              state.roles_BAJADEUSUARIOS   = valor 
            
 },
 mutation_MODIFICACIONDEUSUARIOS( state, valor) {   
              state.roles_MODIFICACIONDEUSUARIOS   = valor 
            
 },
 mutation_ALTADEROL( state, valor) {   
              state.roles_ALTADEROL   = valor 
            
 },
 mutation_BAJADEROL( state, valor) {   
              state.roles_BAJADEROL   = valor 
            
 },
 mutation_MODIFICACIONDEROL( state, valor) {   
              state.roles_MODIFICACIONDEROL   = valor 
            
 },
 mutation_ALTADEVALORACIONINICIAL( state, valor) {   
              state.roles_ALTADEVALORACIONINICIAL   = valor 
            
 },
 mutation_MODIFICACIONREAPERTURAVALORACIONINICIAL( state, valor) {   
              state.roles_MODIFICACIONREAPERTURAVALORACIONINICIAL   = valor 
            
 },
 mutation_EDITARANTESDECIERREDELAVALORACIONINICIAL( state, valor) {   
              state.roles_EDITARANTESDECIERREDELAVALORACIONINICIAL   = valor 
            
 },
 mutation_BAJAVALORACIONINICIAL( state, valor) {   
              state.roles_BAJAVALORACIONINICIAL   = valor 
            
 },
 mutation_IMPRESIONVALORACIONINICIAL( state, valor) {   
              state.roles_IMPRESIONVALORACIONINICIAL   = valor 
            
 },
 mutation_VISUALIZACIONVALORACIONINICIAL( state, valor) {   
              state.roles_VISUALIZACIONVALORACIONINICIAL   = valor 
            
 },
 mutation_ALTADEVALORACIONINTEGRAL( state, valor) {   
              state.roles_ALTADEVALORACIONINTEGRAL   = valor 
            
 },
 mutation_MODIFICACIONREAPERTURAVALORACIONINTEGRAL( state, valor) {   
              state.roles_MODIFICACIONREAPERTURAVALORACIONINTEGRAL   = valor 
            
 },
 mutation_EDITARANTESDECIERREDELAVALORACIONINTEGRAL( state, valor) {   
              state.roles_EDITARANTESDECIERREDELAVALORACIONINTEGRAL   = valor 
            
 },
 mutation_BAJAVALORACIONINTEGRAL( state, valor) {   
              state.roles_BAJAVALORACIONINTEGRAL   = valor 
            
 },
 mutation_IMPRESIONVALORACIONINTEGRAL( state, valor) {   
              state.roles_IMPRESIONVALORACIONINTEGRAL   = valor 
            
 },
 mutation_VISUALIZACIONVALORACIONINTEGRAL( state, valor) {   
              state.roles_VISUALIZACIONVALORACIONINTEGRAL   = valor 
            
 },
 mutation_ALTADESEGUIMIENTO( state, valor) {   
              state.roles_ALTADESEGUIMIENTO   = valor 
            
 },
 mutation_MODIFICACIONDESEGUIMIENTO( state, valor) {   
              state.roles_MODIFICACIONDESEGUIMIENTO   = valor 
            
 },
 mutation_EDITARDESEGUIMIENTO( state, valor) {   
              state.roles_EDITARDESEGUIMIENTO   = valor 
            
 },
 mutation_BAJADESEGUIMIENTO( state, valor) {   
              state.roles_BAJADESEGUIMIENTO   = valor 
            
 },
 mutation_IMPRESIONDESEGUIMIENTO( state, valor) {   
              state.roles_IMPRESIONDESEGUIMIENTO   = valor 
            
 },
 mutation_VISUALIZACIONDESEGUIMIENTO( state, valor) {   
              state.roles_VISUALIZACIONDESEGUIMIENTO   = valor 
            
 },
 mutation_ALTADECIERRE( state, valor) {   
              state.roles_ALTADECIERRE   = valor 
            
 },
 mutation_MODIFICACIONDECIERRE( state, valor) {   
              state.roles_MODIFICACIONDECIERRE   = valor 
            
 },
 mutation_EDICIONDECIERRE( state, valor) {   
              state.roles_EDICIONDECIERRE   = valor 
            
 },
 mutation_BAJADECIERRE( state, valor) {   
              state.roles_BAJADECIERRE   = valor 
            
 },
 mutation_IMPRESIONDECIERRE( state, valor) {   
              state.roles_IMPRESIONDECIERRE   = valor 
            
 },
 mutation_VISUALIZACIONDECIERRE( state, valor) {   
              state.roles_VISUALIZACIONDECIERRE   = valor 
            
 },
 mutation_ALTADENUNCIA( state, valor) {   
              state.roles_ALTADENUNCIA   = valor 
            
 },
 mutation_MODIFCACIONDENUNCIA( state, valor) {   
              state.roles_MODIFCACIONDENUNCIA   = valor 
            
 },
 mutation_EDICIONDENUNCIA( state, valor) {   
              state.roles_EDICIONDENUNCIA   = valor 
            
 },
 mutation_BAJADENUNCIA( state, valor) {   
              state.roles_BAJADENUNCIA   = valor 
            
 },
 mutation_IMPRESIONDENUNCIA( state, valor) {   
              state.roles_IMPRESIONDENUNCIA   = valor 
            
 },
 mutation_VISUALIZACIONDENUNCIA( state, valor) {   
              state.roles_VISUALIZACIONDENUNCIA   = valor 
            
 },
 mutation_ALTAINVESTIGACION( state, valor) {   
              state.roles_ALTAINVESTIGACION   = valor 
            
 },
 mutation_MODIFICACIONINVESTIGACION( state, valor) {   
              state.roles_MODIFICACIONINVESTIGACION   = valor 
            
 },
 mutation_EDICIONINVESTIGACION( state, valor) {   
              state.roles_EDICIONINVESTIGACION   = valor 
            
 },
 mutation_BAJAINVESTIGACION( state, valor) {   
              state.roles_BAJAINVESTIGACION   = valor 
            
 },
 mutation_IMPRESIONINVESTIGACION( state, valor) {   
              state.roles_IMPRESIONINVESTIGACION   = valor 
            
 },

 mutation_VISUALIZACIONINVESTIGACION( state, valor) {   
  state.roles_VISUALIZACIONINVESTIGACION   = valor 

},
 mutation_ALTAEVIDENCIA( state, valor) {   
              state.roles_ALTAEVIDENCIA   = valor 
            
 },
 mutation_MODIFCACIONEVIDENCIA( state, valor) {   
              state.roles_MODIFCACIONEVIDENCIA   = valor 
            
 },
 mutation_EDICIONEVIDENCIA( state, valor) {   
              state.roles_EDICIONEVIDENCIA   = valor 
            
 },
 mutation_BAJAEVIDENCIA( state, valor) {   
              state.roles_BAJAEVIDENCIA   = valor 
            
 },
 mutation_IMPRESIONEVIDENCIA( state, valor) {   
              state.roles_IMPRESIONEVIDENCIA   = valor 
            
 },
 mutation_VISUALIZACIONEVIDENCIA( state, valor) {   
              state.roles_VISUALIZACIONEVIDENCIA   = valor 
            
 },
 mutation_ALTADEARCHIVOS( state, valor) {   
              state.roles_ALTADEARCHIVOS   = valor 
            
 },
 mutation_MODIFICACIONARCHIVOS( state, valor) {   
              state.roles_MODIFICACIONARCHIVOS   = valor 
            
 },
 mutation_IMPRESIONARCHIVOS( state, valor) {   
              state.roles_IMPRESIONARCHIVOS   = valor 
            
 },
 mutation_VISUALIZACIONARCHIVOS( state, valor) {   
              state.roles_VISUALIZACIONARCHIVOS   = valor 
            
 },

 mutation_roles_activo( state, valor) {   
  state.roles_ACTIVO   = valor 

},


mutation_roles_RECIBECORREOS( state, valor) {   
  state.roles_RECIBECORREOS   = valor 

},      

mutation_roles_VISIBILIDADDEINCIDENTES( state, valor) {   
  state.roles_VISIBILIDADDEINCIDENTES   = valor 

},

mutation_roles_AUTORIZAIMPRESION( state, valor) {   
  state.roles_AUTORIZAIMPRESION   = valor 

},


}