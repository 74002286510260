export default { 

     mutation_usuarios_tokenUsuario(state, valor) {
       
          state.usuarios_tokenUsuario = valor;

     },
 
    mutation_usuarios_id( state, valor) {   
              state.usuarios_id   = valor 
            
 },
 mutation_usuarios_nombre( state, valor) {   
              state.usuarios_nombre   = valor 
            
 },
 mutation_usuarios_email( state, valor) {   
              state.usuarios_email   = valor 
            
 },
 
 mutation_usuarios_password( state, valor) {   
              state.usuarios_password   = valor 
            
 },
 mutation_usuarios_rol( state, valor) {   
              state.usuarios_rol   = valor 
            
 },
 mutation_usuarios_programa( state, valor) {   
              state.usuarios_programa   = valor 
            
 },
 mutation_usuarios_fechaCreacion( state, valor) {   
              state.usuarios_fechaCreacion   = valor 
            
 },
 mutation_usuarios_activo( state, valor) {   
              state.usuarios_activo   = valor 
            
 },
 mutation_usuariologueado(state, valor){
      state.usuarios_usuariologueado   = valor 
 },

 mutation_usuariologueado_rol(state, valor){
     state.usuarios_usuariologueado_rol   = valor 
},
}