import mutations from './mutations.js'; 
 import actions from './actions.js'; 
 
 export default { 
 
     state : {                            
         conciencia_id: null  ,
         conciencia_estatus: null  ,
         conciencia_clasificacion: null  ,
         conciencia_activo: null  ,
         conciencia_tipo: null  ,


         conciencia_docto: 0  ,
         conciencia_estatusplan: null,

         conciencia_primermonitoreo       : null,     
         conciencia_segundomonitoreo      :  null,

         conciencia_primermonitoreo_docto :0,
         conciencia_segundomonitoreo_docto: 0,

         conciencia_carpeta               : null,       
         conciencia_carpeta_docto         : 0,     
         
         conciencia_estado                : null,      

              },          mutations, actions 
   }

