import mutations from './mutations.js'; 
 import actions from './actions.js'; 
 
 export default { 
 
     state : { 
       
        parametros_id: null  ,
        parametros_nombreParametro: null  ,
        parametros_descripcion: null  ,
        parametros_valor: null  ,
        parametros_conexionsas : '',
              },          mutations, actions 
   }