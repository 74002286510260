export default { 
 
actions_permisosimpresion_id(context,valor){ 
     context.commit('mutation_permisosimpresion_id',valor); 
     },


 actions_permisosimpresion_usuarioid(context,valor){ 
    context.commit('mutation_permisosimpresion_usuarioid',valor); 
     },


 actions_permisosimpresion_incidenteid(context,valor){ 
    context.commit('mutation_permisosimpresion_incidenteid',valor); 
     },


 actions_permisosimpresion_etapa(context,valor){ 
      context.commit('mutation_permisosimpresion_etapa',valor); 
     },


 actions_permisosimpresion_password(context,valor){ 
      context.commit('mutation_permisosimpresion_password',valor); 
     },


 actions_permisosimpresion_respuesta(context,valor){ 
      context.commit('mutation_permisosimpresion_respuesta',valor); 
     },


 actions_permisosimpresion_usuarioidautorizo(context,valor){ 
     context.commit('mutation_permisosimpresion_usuarioidautorizo',valor); 
     },


 actions_permisosimpresion_vigente(context,valor){ 
  context.commit('mutation_permisosimpresion_vigente',valor); 
     },

 actions_permisosimpresion_fechapeticion(context,valor){ 
    context.commit('mutation_permisosimpresion_fechapeticion',valor); 
     },


 actions_permisosimpresion_fechaautorizacion(context,valor){ 
      context.commit('mutation_permisosimpresion_fechaautorizacion',valor); 
     },



 }