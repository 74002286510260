export default { 
 
      action_id ( context , valor ) { 
                         context.commit('mutation_id',valor)  
                        
  }  ,
 
 action_nombre ( context , valor ) { 
                         context.commit('mutation_nombre',valor)  
                        
  }  
 ,
 action_descripcion ( context , valor ) { 
                         context.commit('mutation_descripcion',valor)  
                        
  }  
 ,
 action_ubicacion ( context , valor ) { 
                         context.commit('mutation_ubicacion',valor)  
                        
  }  
 ,
 action_tipo ( context , valor ) { 
                         context.commit('mutation_tipo',valor)  
                        
  }  
 ,
 action_investigacionid ( context , valor ) { 
                         context.commit('mutation_investigacionid',valor)  
                        
  }  
 ,
 action_activo ( context , valor ) { 
                         context.commit('mutation_activo',valor)  
                        
  }  ,
  }