export default {

    mutation_doctosapoyo_id( state, valor) {   
              state.doctosapoyo_id = valor 
            
 },
 mutation_doctosapoyo_nombredocto( state, valor) {   
              state.doctosapoyo_nombredocto = valor 
            
 },
 mutation_doctosapoyo_incidenteid( state, valor) {   
              state.doctosapoyo_incidenteid = valor 
            
 },
 mutation_doctosapoyo_descripcion( state, valor) {   
              state.doctosapoyo_descripcion = valor 
            
 },
 mutation_doctosapoyo_link( state, valor) {   
              state.doctosapoyo_link = valor 
            
 },
 mutation_doctosapoyo_categoria( state, valor) {   
              state.doctosapoyo_categoria = valor 
            
 },
 mutation_doctosapoyo_activo( state, valor) {   
              state.doctosapoyo_activo = valor 
            
 }
}