import mutations from './mutations.js'; 
 import actions from './actions.js'; 
 
 export default { 
 
    state: {
evidencias_id: null  ,
evidencias_nombre: null  ,
evidencias_descripcion: null  ,
evidencias_ubicacion: null  ,
evidencias_tipo: null  ,
evidencias_investigacionid: null  ,
evidencias_activo: null  ,
    },
    mutations,
    actions 
   }