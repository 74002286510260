import mutations from './mutations.js'; 
 import actions from './actions.js'; 
 
 export default { 
 
     state : {    
         
        notificacion_incidenteid: null  ,
        notificacion_respuesta: null  ,
        notificacion_texto_respuesta: null  ,

              },   
              
              mutations, 
              actions 
   }