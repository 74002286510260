import mutations from './mutations.js'; 
 import actions from './actions.js'; 
 
 export default { 
 
    state: {
id_para_imprimir: null  ,

    },
    mutations,
    actions 
   }