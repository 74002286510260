export default { 
 
  mutation_conciencia_id( state, valor) {   
              state.conciencia_id = valor 
            
 },
 mutation_conciencia_estatus( state, valor) {   
              state.conciencia_estatus = valor 
            
 },
 mutation_conciencia_clasificacion( state, valor) {   
              state.conciencia_clasificacion = valor 
            
 },
 mutation_conciencia_activo( state, valor) {   
              state.conciencia_activo = valor 
            
 },
 mutation_conciencia_tipo( state, valor) {   
              state.conciencia_tipo = valor 
            
 },

 mutation_conciencia_docto( state, valor) {   
  state.conciencia_docto = valor 

},

mutation_conciencia_estatusplan( state, valor) {   
  state.conciencia_estatusplan = valor 

},


mutation_conciencia_primermonitoreo( state, valor) { 
  state.conciencia_primermonitoreo = valor 

} ,     
mutation_conciencia_segundomonitoreo( state, valor) { 
  state.conciencia_segundomonitoreo = valor 

} ,

mutation_conciencia_primermonitoreo_docto ( state, valor) { 
  state.conciencia_primermonitoreo_docto = valor 

} ,
mutation_conciencia_segundomonitoreo_docto ( state, valor) {
  state.conciencia_segundomonitoreo_docto = valor 

}  ,

mutation_conciencia_carpeta        ( state, valor) {  
  state.conciencia_carpeta = valor 

},       
mutation_conciencia_carpeta_docto  ( state, valor) {  
  state.conciencia_carpeta_docto = valor 

},     
mutation_conciencia_estado         ( state, valor) { 
  state.conciencia_estado = valor 

} ,   



                                                                           }